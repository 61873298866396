<template>
  <div class="text-center">
    <h5>Yetkiniz bulunmamaktadır.</h5>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
