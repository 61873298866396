import Modules from "./Modules";
import ModuleLocked from "../components/Packages/ModuleLocked.vue";
export default {
  components: {
    ModuleLocked,
  },
  mixins: [Modules],
  data() {
    return {
      mailIsSending: false,
      emailForm: {
        to: "",
        bcc: "",
        subject: "",
        content: "",
        files: [],
      },
    };
  },
  computed: {
    getMailDrawer() {
      return this.$store.state.mailDrawer;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  watch: {
    "getMailDrawer.status"(data) {
      if (data) this.GET_MAIL_FIELDS();
    },
  },
  methods: {
    CLEAR_FORM(callback) {
      this.emailForm = {
        to: "",
        bcc: "",
        subject: "",
        content: "",
        files: [],
      };
      if (callback()) callback();
    },
    SET_FILE(e) {
      if (this.emailForm.files.length + e.target.files.length > 10)
        return this.$store.commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: this.$t("limit10Files"),
          },
        });
      this.emailForm.files = [...this.emailForm.files, ...e.target.files];
    },
    async SEND_TO_MAIL(e, callback = () => false) {
      e.preventDefault();
      const fd = new FormData();
      fd.append("to", this.emailForm.to);
      fd.append("subject", this.emailForm.subject);
      fd.append("content", this.emailForm.content);
      fd.append("bcc", this.emailForm.bcc);
      if (this.emailForm.files.length !== 0) {
        for (let i = 0; i < this.emailForm.files.length; i++) {
          const element = this.emailForm.files[i];
          fd.append("files", element);
        }
      }
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: false,
          variant: "",
          message: "",
        },
      });
      const response = await this.$api.post("Users/SendMail", fd);
      if (response.message === "OK" && response.result === "OK") {
        this.$store.commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: this.$t("mailHasbeenSent"),
          },
        });
        this.CLEAR_FORM(callback);
        this.$store.commit("SET_MAIL_DRAWER", { status: false, form: {} });
      } else {
        this.$store.commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: this.$t("processIsFailedPleaseTryAgain"),
          },
        });
      }
    },
    GET_MAIL_FIELDS() {
      setTimeout(() => {
        if (this.getMailDrawer.status) {
          this.emailForm = { ...this.getMailDrawer.form };
        }
      }, 150);
    },
  },
};
